import React, { useRef, useState, TouchEvent, useEffect } from "react";

import promo1 from "../../assets/img/promos/promo-1.jpg";
import promo2 from "../../assets/img/promos/promo-2.jpg";
import promo3 from "../../assets/img/promos/promo-3.jpg";
import promo4 from "../../assets/img/promos/promo-4.jpg";
import promo5 from "../../assets/img/promos/promo-5.jpg";
import promo6 from "../../assets/img/promos/promo-6.jpg";
import promo7 from "../../assets/img/promos/promo-7.jpg";
import promo8 from "../../assets/img/promos/promo-8.jpg";
import promo9 from "../../assets/img/promos/promo-9.jpg";
import promo10 from "../../assets/img/promos/promo-10.jpg";
import promo11 from "../../assets/img/promos/promo-11.jpg";
import promo12 from "../../assets/img/promos/promo-12.jpg";
import promo13 from "../../assets/img/promos/promo-13.jpg";
import promo14 from "../../assets/img/promos/promo-14.jpg";
import promo15 from "../../assets/img/promos/promo-15.jpg";
import promo16 from "../../assets/img/promos/promo-16.jpg";
import promo17 from "../../assets/img/promos/promo-17.jpg";
import promo18 from "../../assets/img/promos/promo-18.jpg";
import promo19 from "../../assets/img/promos/promo-19.jpg";
import promo20 from "../../assets/img/promos/promo-20.jpg";
import promo21 from "../../assets/img/promos/promo-21.jpg";
import promo22 from "../../assets/img/promos/promo-22.jpg";
import promo23 from "../../assets/img/promos/promo-23.jpg";
import promo24 from "../../assets/img/promos/promo-24.jpg";
import promo25 from "../../assets/img/promos/promo-25.jpg";
import promo26 from "../../assets/img/promos/promo-26.jpg";
import promo27 from "../../assets/img/promos/promo-27.jpg";
import promo28 from "../../assets/img/promos/promo-28.jpg";
import promo29 from "../../assets/img/promos/promo-29.jpg";
import promo30 from "../../assets/img/promos/promo-30.jpg";
import promo31 from "../../assets/img/promos/promo-31.jpg";
import promo32 from "../../assets/img/promos/promo-32.jpg";
import promo33 from "../../assets/img/promos/promo-33.jpg";
import promo34 from "../../assets/img/promos/promo-34.jpg";
import promo35 from "../../assets/img/promos/promo-35.jpg";
import promo36 from "../../assets/img/promos/promo-36.jpg";
import promo37 from "../../assets/img/promos/promo-37.jpg";
import promo38 from "../../assets/img/promos/promo-38.jpg";
import promo39 from "../../assets/img/promos/promo-39.jpg";
import promo40 from "../../assets/img/promos/promo-40.png";
import promo41 from "../../assets/img/promos/promo-41.png";

import {
  ArrowContainer,
  ArrowLeft,
  ArrowRight,
  Dot,
  DotContainer,
  PromotionContainer,
  PromotionImg,
  PromotionImgsContainer,
} from "./promotion.styles";

const promos = [
  promo1,
  promo2,
  promo3,
  promo4,
  promo5,
  promo6,
  promo7,
  promo8,
  promo9,
  promo10,
  promo11,
  promo12,
  promo13,
  promo14,
  promo15,
  promo16,
  promo17,
  promo18,
  promo19,
  promo20,
  promo21,
  promo22,
  promo23,
  promo24,
  promo25,
  promo26,
  promo27,
  promo28,
  promo29,
  promo30,
  promo31,
  promo32,
  promo33,
  promo34,
  promo35,
  promo36,
  promo37,
  promo38,
  promo39,
  promo40,
  promo41,
];

const Promotion = () => {
  const [currentPromo, setCurrentPromo] = useState(0);
  const promoContainerRef = useRef<null | HTMLDivElement>(null);
  const [touchStart, setTouchStart] = useState<null | number>(null);
  const [touchEnd, setTouchEnd] = useState<null | number>(null);
  const minSwipeDistance = 70;

  setInterval(() => promoScrollForward(), 10000);

  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent<HTMLDivElement>) =>
    setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) promoScrollForward();
    if (isRightSwipe) promoScrollBack();
  };

  const promoScrollForward = () => {
    const screenWidth = window.outerWidth;
    const nextPromo = currentPromo === promos.length - 1 ? 0 : currentPromo + 1;
    if (screenWidth > 750)
      promoContainerRef.current?.scrollTo({
        left: nextPromo * (screenWidth * 0.892) + 95 * nextPromo,
        behavior: "smooth",
      });
    else
      promoContainerRef.current?.scrollTo({
        left: nextPromo * screenWidth + 40 * nextPromo,
        behavior: "smooth",
      });
    setCurrentPromo(nextPromo);
  };

  const promoScrollBack = () => {
    const screenWidth = window.outerWidth;
    const nextPromo = currentPromo === 0 ? promos.length - 1 : currentPromo - 1;
    if (screenWidth > 750)
      promoContainerRef.current?.scrollTo({
        left: nextPromo * (screenWidth * 0.892) + 95 * nextPromo,
        behavior: "smooth",
      });
    else
      promoContainerRef.current?.scrollTo({
        left: nextPromo * screenWidth + 40 * nextPromo,
        behavior: "smooth",
      });
    setCurrentPromo(nextPromo);
  };

  return (
    <PromotionContainer>
      <ArrowContainer>
        <ArrowLeft onClick={promoScrollBack} />
        <ArrowRight onClick={promoScrollForward} />
      </ArrowContainer>
      <PromotionImgsContainer
        ref={promoContainerRef}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {promos.map((img, i) => (
          <PromotionImg src={img} key={i} />
        ))}
      </PromotionImgsContainer>
      <DotContainer>
        {promos.map((_, i) => (
          <Dot $isSelected={i === currentPromo} key={i} />
        ))}
      </DotContainer>
    </PromotionContainer>
  );
};

export default Promotion;
