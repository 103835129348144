import React, { useContext, useEffect, useState } from "react";

import { LngContext } from "../context/lng-context";
import testImg from "../assets/img/tea.png";
import { getCatalogItems } from "../services/api";

import { CatalogHeader } from "../components/header/header.component";
import {
  CatalogContainer,
  CatalogItem,
  CatalogItemImg,
  CatalogItemText,
  CatalogItemTitle,
  CatalogPageContainer,
  CatalogTitle,
  Category,
  CategoryContainer,
  Package,
  PackagesContainer,
  TeaSpecificsContainer,
  WhiteDot,
} from "../components/catalog/catalog.styles";

const CatalogPage = () => {
  const allTexts = useContext(LngContext);
  const texts = allTexts.catalogPage;

  type TeaType = keyof typeof texts.teaSpecifics;
  type PackageType = keyof typeof texts.packages;
  type CatalogItemType = {
    id: number;
    priority?: number;
    name: string;
    field1: string;
    field2: string;
    field3: string;
    category: TeaType;
    package: PackageType;
    img_url: string;
  };

  const [catalogItems, setCatalogItems] = useState<CatalogItemType[]>([]);
  const [currentTea, setCurrentTea] = useState<TeaType>(
    Object.keys(texts.types)[0] as TeaType
  );
  const [currentTeaPackage, setCurrentTeaPackage] =
    useState<PackageType | null>(null);

  useEffect(() => {
    getCatalogItems().then((data) => setCatalogItems(data));
  }, []);

  return (
    <div style={{ background: texts.teaSpecifics[currentTea].color }}>
      <CatalogHeader text={allTexts.topMenu.catalogPage} />
      <CatalogPageContainer>
        <CategoryContainer>
          {Object.keys(texts.types).map((type) => (
            <Category
              key={type}
              $active={currentTea === type}
              onClick={() => setCurrentTea(type as TeaType)}
            >
              {texts.types[type as TeaType]}
            </Category>
          ))}
        </CategoryContainer>
        <CatalogTitle>{texts.types[currentTea]}</CatalogTitle>
        <span>{texts.teaSpecifics[currentTea].title}</span>
        <TeaSpecificsContainer>
          {texts.teaSpecifics[currentTea].specifics.map((specific) => (
            <div key={specific}>
              <WhiteDot /> <span>{specific}</span>
            </div>
          ))}
        </TeaSpecificsContainer>
        <PackagesContainer>
          {Object.keys(texts.packages).map((pack) => (
            <Package
              key={pack}
              $active={currentTeaPackage === pack}
              onClick={() =>
                currentTeaPackage !== pack
                  ? setCurrentTeaPackage(pack as PackageType)
                  : setCurrentTeaPackage(null)
              }
            >
              {texts.packages[pack as PackageType]}
            </Package>
          ))}
        </PackagesContainer>
        <span>{texts.teaSpecifics[currentTea].text}</span>
        <CatalogContainer>
          {catalogItems
            .filter((item) => item.category === currentTea)
            .sort((a, b) => (a.priority || 0) - (b.priority || 0))
            .filter(
              (item) => !currentTeaPackage || item.package === currentTeaPackage
            )
            .map((item) => (
              <CatalogItem key={item.id}>
                <CatalogItemImg src={item.img_url} />
                <CatalogItemTitle>{item.name}</CatalogItemTitle>
                <CatalogItemText>
                  {texts.category}: {texts.types[item.category]}
                </CatalogItemText>
                <CatalogItemText>{item.field1}</CatalogItemText>
                <CatalogItemText>{item.field2}</CatalogItemText>
                <CatalogItemText>{item.field3}</CatalogItemText>
                <CatalogItemText>
                  {texts.package}: {texts.packages[item.package]}
                </CatalogItemText>
              </CatalogItem>
            ))}
        </CatalogContainer>
      </CatalogPageContainer>
    </div>
  );
};

export default CatalogPage;
