import React, { FC, useContext } from "react";

import { LngContext } from "../../context/lng-context";
import img from "../../assets/img/tea-on-tabel.png";
import {
  ClassBannerContainer,
  ClassBannerImgLeft,
  ClassBannerImgRight,
} from "./class-banner.styles";

const ClassBanner: FC<{ onClick: () => void }> = ({ onClick }) => {
  const allTexts = useContext(LngContext);
  const texts = allTexts.classBanner;

  return (
    <ClassBannerContainer onClick={onClick}>
      <ClassBannerImgLeft src={img} />
      <ClassBannerImgRight src={img} />
      <div>
        {texts.title}
        <br />
        <span>{texts.titleGreen}</span>
        <br />
        {texts.titleBlue}
      </div>
      <button>{texts.button}</button>
    </ClassBannerContainer>
  );
};

export default ClassBanner;
