import styled from "styled-components";

export const CatalogPageContainer = styled.div`
  width: 100%;
  /* margin-top: 20px; */
  box-sizing: border-box;
  padding: 0 5.4vw 0;

  span {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    @media screen and (max-width: 750px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

export const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 13px;
  margin-top: 27px;
  overflow-x: scroll;

  @media screen and (max-width: 750px) {
    margin-top: 12px;
    gap: 4px;
  }
`;

interface CategoryProps {
  $active: boolean;
}

export const Category = styled.span<CategoryProps>`
  cursor: pointer;
  font-weight: 600;
  color: ${({ $active }) => ($active ? "white" : "black")};
  background: ${({ $active }) => ($active ? "#30AF1B" : "white")};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  min-width: 232.99px;
  height: 48px;
  border-radius: 50px;

  @media screen and (max-width: 750px) {
    min-width: 173.11px;
    height: 40px;
  }
`;

export const CatalogTitle = styled.h1`
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 65px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 40px;
  margin-bottom: 12px;

  @media screen and (max-width: 750px) {
    margin-top: 36px;
    margin-bottom: 8px;
    font-size: 28px;
  }
`;

export const PackagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  overflow-y: hidden;
  gap: 10px;
  margin-bottom: 32px;

  @media screen and (max-width: 750px) {
    margin-bottom: 26px;
  }
`;

export const Package = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);

  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 65px;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;

  color: ${({ $active }) => ($active ? "rgba(47, 174, 27, 1)" : "black")};
  border: ${({ $active }) =>
    $active ? "3px solid rgba(47, 174, 27, 1)" : "none"};

  height: 47px;
  width: 266px;
  min-width: 266px;
`;

export const TeaSpecificsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 12px;
  margin-bottom: 28px;

  @media screen and (max-width: 750px) {
    gap: 12px;
    margin-top: 21px;
    margin-bottom: 16px;
  }

  div {
    width: 413px;
    height: 51px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #30af1b;
    gap: 13px;
    color: white;

    @media screen and (max-width: 750px) {
      width: 100%;
      justify-content: flex-start;
      padding-left: 24px;
    }
  }
`;

export const WhiteDot = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
`;

export const CatalogContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 44px;
  padding-bottom: 181px;

  @media screen and (max-width: 1800px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 750px) {
    gap: 8px;
    margin-top: 17px;
    padding-bottom: 100px;
    display: grid;
  }
`;

export const CatalogItem = styled.div`
  /* width: 100%; */
  height: 550px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #0000004d;
  border-radius: 16.86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  padding-bottom: 66px;
  gap: 12px;

  @media screen and (max-width: 750px) {
    height: 320px;
    padding: 10px;
    padding-bottom: 36px;
    gap: 0;
  }
`;

export const CatalogItemImg = styled.img`
  width: 100%;
  height: 295px;
  object-fit: cover;
  border-radius: 10px;

  @media screen and (max-width: 750px) {
    height: 161px;
    max-height: 161px;
    min-height: 161px;
  }
`;

export const CatalogItemTitle = styled.div`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media screen and (max-width: 750px) {
    font-size: 12px;
    line-height: 24px;
  }
`;

export const CatalogItemText = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media screen and (max-width: 750px) {
    font-size: 10px;
    line-height: 20px;
  }
`;
